import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../variables.js";
import loadingGif from "../assets/loading.gif";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faAngleRight,
  faAngleLeft,
  faForwardStep,
  faBackwardStep,
  faSquare,
  faClipboardCheck,
  faCircleXmark,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import FundamentalFilter from "./fundamentalFilter.jsx";
import { fundamentalData } from "./predictData.js";

function Fundamental() {
  const [reports, setReports] = useState();
  // const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/cp/getpaginated?categoryArg=["خودرو و ساخت قطعات"]`,
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((response) => {
        // console.log(response.data.stocks[0].fundamental.length);
        // setTotalCount(response.data.totalCount);
        setReports(response.data.stocks);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("userName");
          localStorage.removeItem("token");
          toast("به دلیل گذشت زمان باید دوباره وارد حساب خود شوید.");
          setTimeout(() => {
            window.location = "/";
          }, 1000);
        } else {
          toast("مشکلی پیش آمد");
        }
      });
  }, [currentPage]);

  function goToPreviousPage() {
    setLoading(true);
    setCurrentPage(currentPage - 1);
  }
  function goToNextPage() {
    setLoading(true);
    setCurrentPage(currentPage + 1);
  }
  function goToFirstPage() {
    setLoading(true);
    setCurrentPage(1);
  }
  function goToLastPage() {
    setLoading(true);
    // setCurrentPage(totalCount);
  }

  return (
    <div>
      <FundamentalFilter setter={setReports} />
      <hr />
      <h2 style={{ textAlign: "right", color: "#00755e" }}>گزارش‌های کدال</h2>
      <div id="fundamentalHelp">
        <div style={{ display: "block" }}>
          <FontAwesomeIcon
            icon={faBell}
            style={{ color: "gold", display: "inline" }}
          />
          <p>اهمیت زیاد</p>

          <FontAwesomeIcon icon={faBell} style={{ color: "silver" }} />
          <p>اهمیت متوسط</p>

          <FontAwesomeIcon icon={faBell} style={{ color: "chocolate" }} />
          <p>اهمیت کم</p>
        </div>
        <div style={{ display: "block" }}>
          <FontAwesomeIcon
            icon={faSquare}
            style={{ color: "green", display: "inline" }}
          />
          <p>بهتر از گزارش مشابه دوره قبلی</p>

          <FontAwesomeIcon
            icon={faSquare}
            style={{ color: "gray", display: "inline" }}
          />
          <p>بدون تغییر نسبت به گزارش مشابه دوره قبلی</p>

          <FontAwesomeIcon
            icon={faSquare}
            style={{ color: "red", display: "inline" }}
          />
          <p>ضعیف‌تر از گزارش مشابه دوره قبلی</p>
        </div>
      </div>
      <div className="tablesContainer">
        <table className="commonTable" id="fundamentalTable">
          <thead>
            <tr>
              <th>تاریخ گزارش</th>
              <th>سهم</th>
              <th>نوع گزارش</th>
              <th>اهمیت</th>
              <th>دوره (ماه)</th>
              <th>وضعیت حسابرسی</th>
              <th>تلفیقی</th>
              <th>مقدار در گزارش قبلی</th>
              <th>مقدار در گزارش فعلی</th>
              <th>دانلود گزارش</th>
            </tr>
          </thead>
          <tbody>
            {reports ? (
              reports.map((dates, index) => (
                <React.Fragment key={index}>
                  {dates.fundamental.map((item, indexReport) => (
                    <tr key={indexReport}>
                      {indexReport == 0 ? (
                        <td
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "white",
                          }}
                          rowspan={dates.fundamental.length}
                        >
                          {dates.date.slice(0, 4)}/{dates.date.slice(4, 6)}/
                          {dates.date.slice(6, 8)}
                        </td>
                      ) : null}
                      <td style={{ fontWeight: "bold" }}>{dates.symbol}</td>

                      <td>{item.title}</td>

                      <td>
                        <FontAwesomeIcon
                          icon={faBell}
                          style={{
                            color: `${
                              item.importance == 2
                                ? "gold"
                                : item.importance == 1
                                ? "silver"
                                : "chocolate"
                            }`,
                          }}
                        />
                      </td>
                      <td>{item.fiscal_period}</td>
                      <td>
                        {item.is_audited ? (
                          <FontAwesomeIcon icon={faClipboardCheck} />
                        ) : (
                          <FontAwesomeIcon icon={faCircleXmark} />
                        )}
                      </td>
                      <td>{item.is_combined ? "بله" : "خیر"}</td>
                      <td>
                        {item.value_prev !== null
                          ? item.value_prev.toLocaleString()
                          : ""}
                      </td>

                      <td
                        style={{
                          backgroundColor: `${
                            item.color == 1
                              ? "green"
                              : item.color == 0
                              ? "gray"
                              : "red"
                          }`,
                          color: "white",
                        }}
                      >
                        {item.value !== null ? item.value.toLocaleString() : ""}
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => toast("دریافت گزارش در دسترس نیست")}
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ color: "#0077b6" }}
                        />
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={12}>
                  <img src={loadingGif} className="loadingGif" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <hr />
      <h2 style={{ textAlign: "right", color: "#00755e" }}>مولفه‌های بنیادی</h2>
      <div className="tablesContainer">
        <table className="commonTable" id="fundamentalTable">
          <thead>
            <tr>
              <th>سهم</th>
              <th>سود خالص</th>
              <th>EPS</th>
              <th>P/E</th>
              <th>P/S</th>
              <th>ROA</th>
              <th>حاشیه سود خالص</th>
            </tr>
          </thead>
          <tbody>
            {fundamentalData ? (
              fundamentalData.map((item, index) => (
                <tr key={index}>
                  <td style={{ fontWeight: "bold" }}>{item.stockTitle}</td>
                  <td>{item.pure_profit.toLocaleString()}</td>
                  <td>{item.eps.toLocaleString()}</td>
                  <td>{item.pe.toFixed(2)}</td>
                  <td>{item.ps.toFixed(2)}</td>
                  <td>{item.roa.toFixed(3)}</td>
                  <td>{item.pure_profit_margin.toFixed(3)}</td>
                </tr>
              ))
            ) : (
              <tr style={{ border: "none" }}>
                <td colSpan={10}>
                  <img src={loadingGif} className="loadingGif" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Fundamental;

/* <div id="fundamentalButtons">
        <button onClick={goToFirstPage} disabled={currentPage == 1}>
          <FontAwesomeIcon icon={faForwardStep} />
        </button>
        <button onClick={goToPreviousPage} disabled={currentPage == 1}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>

        {Array.from({ length: 7 }).map((_, index) => {
          const page = currentPage - 3 + index;
          if (page < 1 || page > totalCount) return null;
          return (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              style={{ color: currentPage === page ? "blue" : "white" }}
            >
              {page}
            </button>
          );
        })}

        <button onClick={goToNextPage} disabled={currentPage == totalCount}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <button onClick={goToLastPage} disabled={currentPage == totalCount}>
          <FontAwesomeIcon icon={faBackwardStep} />
        </button>
      </div> */
