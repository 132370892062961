import React, { useEffect, useState } from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import moment from "moment-jalaali";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

moment.loadPersian({ dialect: "persian-modern", usePersianDigits: false });

function Picker(props) {
  // const jalaliDate = moment().format("jYYYYjMMjDD");
  const jalaliDate = props.currentDate;
  const defaultValue = {
    year: parseInt(jalaliDate.slice(0, 4), 10),
    month: parseInt(jalaliDate.slice(4, 6), 10),
    day: parseInt(jalaliDate.slice(6, 8), 10),
  };
  const minimumDate =
    parseInt(jalaliDate.slice(4, 6), 10) == 1
      ? {
          year: parseInt(jalaliDate.slice(0, 4), 10) - 1,
          month: 12,
          day: 1,
        }
      : {
          year: parseInt(jalaliDate.slice(0, 4), 10),
          month: parseInt(jalaliDate.slice(4, 6), 10) - 1,
          day: parseInt(jalaliDate.slice(6, 8), 10),
        };
  const disabledDays = [
    {
      year: 1403,
      month: 8,
      day: 25,
    },
    {
      year: 1403,
      month: 8,
      day: 24,
    },
    {
      year: 1403,
      month: 8,
      day: 18,
    },
    {
      year: 1403,
      month: 8,
      day: 17,
    },
    {
      year: 1403,
      month: 8,
      day: 11,
    },
    {
      year: 1403,
      month: 8,
      day: 10,
    },
    {
      year: 1403,
      month: 8,
      day: 4,
    },
    {
      year: 1403,
      month: 8,
      day: 3,
    },
    {
      year: 1403,
      month: 7,
      day: 27,
    },
    {
      year: 1403,
      month: 7,
      day: 26,
    },
    {
      year: 1403,
      month: 7,
      day: 20,
    },
    {
      year: 1403,
      month: 7,
      day: 19,
    },
    {
      year: 1403,
      month: 7,
      day: 13,
    },
    {
      year: 1403,
      month: 7,
      day: 12,
    },
    {
      year: 1403,
      month: 7,
      day: 6,
    },
    {
      year: 1403,
      month: 7,
      day: 5,
    },
    {
      year: 1403,
      month: 6,
      day: 31,
    },
    {
      year: 1403,
      month: 6,
      day: 30,
    },
    {
      year: 1403,
      month: 6,
      day: 29,
    },
    {
      year: 1403,
      month: 6,
      day: 23,
    },
    {
      year: 1403,
      month: 6,
      day: 22,
    },
    {
      year: 1403,
      month: 6,
      day: 16,
    },
    {
      year: 1403,
      month: 6,
      day: 15,
    },
    {
      year: 1403,
      month: 6,
      day: 14,
    },
    {
      year: 1403,
      month: 6,
      day: 12,
    },
    {
      year: 1403,
      month: 6,
      day: 9,
    },
    {
      year: 1403,
      month: 6,
      day: 8,
    },
    {
      year: 1403,
      month: 6,
      day: 4,
    },
  ];
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const [showDate, setShowDate] = useState(jalaliDate);
  const [enable, setEnable] = useState(false);

  //   useEffect(() => {
  //     axios
  //       .get("http://45.129.36.165:3000/api/api/board/lastDate", {
  //         headers: {
  //           authorization:
  //             "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImthcmVuIiwiY2xhaW1zIjp7InRlY2giOjEsImFpIjoxLCJhdGgiOjEsImJvIjoxfSwiaWF0IjoxNzIwODAzNjc1fQ.vX9PXBuxcU5wmVD8LbznBi1YPuqxHbkcSDnlauRGsiw",
  //         },
  //       })
  //       .then((response) => {
  //         setLastDate(response.data);
  //         // console.log(boardDate);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }, []);

  function handleSelect(e) {
    // console.log(e);
    setSelectedDay(e);
    const output = `${e.year}${e.month < 10 ? 0 : ""}${e.month}${
      e.day < 10 ? 0 : ""
    }${e.day}`;
    props.setter(output);
    setShowDate(output);
    setEnable(false);
  }

  function handleDefault() {
    setSelectedDay(defaultValue);
    const output = `${defaultValue.year}${defaultValue.month < 10 ? 0 : ""}${
      defaultValue.month
    }${defaultValue.day < 10 ? 0 : ""}${defaultValue.day}`;
    props.setter(output);
    setShowDate(output);
    setEnable(false);
  }

  return (
    <div>
      <p
        style={{
          display: "inline",
          fontWeight: "bold",
          backgroundColor: "white",
          padding: "0.6rem",
          border: "1px solid #9c88ff",
          borderRadius: "100px",
          boxShadow: "0 1.5rem 2rem rgba(156, 136, 255, 0.2)",
          color: "#9c88ff",
          cursor: "pointer",
        }}
        onClick={() => setEnable(true)}
      >
        تاریخ امتیازدهی:{" "}
        {`${showDate.slice(0, 4)}/${showDate.slice(4, 6)}/${showDate.slice(
          6,
          8
        )}`}
        <FontAwesomeIcon icon={faCalendar} style={{ marginRight: "1rem" }} />
      </p>

      {enable && (
        <div className="customCalendarContainer">
          <button
            onClick={() => setEnable(false)}
            style={{
              width: "50%",
              border: "solid 2px red",
              borderRadius: "8px",
              color: "red",
              fontWeight: "bold",
              backgroundColor: "white",
            }}
          >
            بستن تقویم
          </button>

          <button
            onClick={handleDefault}
            style={{
              backgroundColor: "white",
              border: "solid 2px #87CEEB",
              width: "50%",
              color: "#87CEEB",
              borderRadius: "7px",
              fontWeight: "bold",
            }}
          >
            برو به آخرین تاریخ
          </button>
          <Calendar
            calendarClassName="custom-calendar"
            value={selectedDay}
            // onChange={(e) => {
            //   console.log(e)
            //   setSelectedDay(e);
            //   setEnable(false);
            //   setShowDate(
            //     `${e.year}${e.month < 10 ? 0 : ""}${e.month}${
            //       e.day < 10 ? 0 : ""
            //     }${e.day}`
            //   );
            // }}
            onChange={handleSelect}
            shouldHighlightWeekends
            locale="fa"
            maximumDate={defaultValue}
            minimumDate={minimumDate}
            disabledDays={disabledDays}
            // renderFooter={() => (
            //   <div
            //     style={{
            //       display: "flex",
            //       justifyContent: "center",
            //       padding: "1rem 2rem",
            //       fontSize: '8px !important',
            //     }}
            //   >
            //     <button
            //       onClick={() => {
            //         setSelectedDay(defaultValue);
            //       }}
            //       style={{
            //         // border: "#0fbcf9",
            //         color: "white",
            //         borderRadius: "7px",
            //         padding: "8px",
            //         backgroundColor: "#87CEEB",
            //         fontWeight:'bold'
            //       }}
            //     >
            //       برو به آخرین تاریخ
            //     </button>
            //   </div>
            // )}
          />
        </div>
      )}
    </div>
  );
}

export default Picker;
