const weeklyMonthly = {
  "68203878405672734": {
    name: "صنوین",
    monthly: 1,
    monthly_confidence: 77.5653772488946,
    weekly: 1,
    weekly_confidence: 75.1615494310856,
  },
  10120557300120078: {
    name: "پسهند",
    monthly: 1,
    monthly_confidence: 75.79192518033088,
    weekly: 1,
    weekly_confidence: 66.91496209514638,
  },
  "65490886290565185": {
    name: "شزنگ",
    monthly: 1,
    monthly_confidence: 63.94441914137987,
    weekly: 1,
    weekly_confidence: 57.43113454369208,
  },
  "44013656953678055": {
    name: "شرانل",
    monthly: 1,
    monthly_confidence: 77.72626545720159,
    weekly: 1,
    weekly_confidence: 79.99777652770281,
  },
  "45284811973404357": {
    name: "یارا",
    monthly: 1,
    monthly_confidence: 56.928247241024465,
    weekly: 1,
    weekly_confidence: 59.210285209243494,
  },
  "18865325633315847": {
    name: "خاتم",
    monthly: 1,
    monthly_confidence: 63.015046798751605,
    weekly: 1,
    weekly_confidence: 67.65528457028171,
  },
  52455922800537930: {
    name: "تکنار",
    monthly: 1,
    monthly_confidence: 71.15258073040417,
    weekly: 1,
    weekly_confidence: 76.43010602841775,
  },
  971068957336171: {
    name: "گارانتی",
    monthly: 1,
    monthly_confidence: 54.41173257192547,
    weekly: 1,
    weekly_confidence: 59.32507288432681,
  },
  "54369290104873523": {
    name: "بتک",
    monthly: 1,
    monthly_confidence: 75.02249838625568,
    weekly: 1,
    weekly_confidence: 72.65818312714498,
  },
  "56574323121551263": {
    name: "شکبیر",
    monthly: 1,
    monthly_confidence: 73.60629209230342,
    weekly: 1,
    weekly_confidence: 72.32975221524636,
  },
  27668158733246204: {
    name: "سپرمی",
    monthly: 1,
    monthly_confidence: 83.74891387228715,
    weekly: 1,
    weekly_confidence: 76.83493921508392,
  },
  10831074117626896: {
    name: "قاروم",
    monthly: 1,
    monthly_confidence: 76.30420259088277,
    weekly: 1,
    weekly_confidence: 70.11964106708763,
  },
  3050342257199174: {
    name: "قنقش",
    monthly: 1,
    monthly_confidence: 78.71251018674425,
    weekly: 1,
    weekly_confidence: 84.65337223891169,
  },
  793710053482057: {
    name: "لپیام",
    monthly: 1,
    monthly_confidence: 52.903535282665075,
    weekly: 1,
    weekly_confidence: 65.93881297595799,
  },
  "37222720235819361": {
    name: "بذر",
    monthly: 1,
    monthly_confidence: 68.11374667604409,
    weekly: 1,
    weekly_confidence: 68.99738956155875,
  },
  "33293588228706998": {
    name: "وپارس",
    monthly: 1,
    monthly_confidence: 68.35974751503451,
    weekly: 1,
    weekly_confidence: 64.17343341004258,
  },
  15521712617204216: {
    name: "سفارس",
    monthly: 1,
    monthly_confidence: 70.33622776423027,
    weekly: 1,
    weekly_confidence: 68.28354186223198,
  },
  "53449700212786324": {
    name: "مارون",
    monthly: 1,
    monthly_confidence: 56.15330252445835,
    weekly: 1,
    weekly_confidence: 61.36231048386544,
  },
  "71666521540545716": {
    name: "غبهار",
    monthly: 2,
    monthly_confidence: 84.06605110771602,
    weekly: 2,
    weekly_confidence: 73.04254602243503,
  },
  17059960254855208: {
    name: "غبهنوش",
    monthly: 1,
    monthly_confidence: 61.40421429636577,
    weekly: 1,
    weekly_confidence: 61.434417833760385,
  },
  "67522512921942106": {
    name: "پادا",
    monthly: 1,
    monthly_confidence: 71.5535002233006,
    weekly: 1,
    weekly_confidence: 65.98048399627052,
  },
  "70309338813767186": {
    name: "فکمند",
    monthly: 1,
    monthly_confidence: 83.60670974701641,
    weekly: 1,
    weekly_confidence: 77.30461165457963,
  },
  "71672399601682259": {
    name: "ثروتم",
    monthly: 1,
    monthly_confidence: 81.22290936486706,
    weekly: 1,
    weekly_confidence: 73.02510097672544,
  },
  "28320293733348826": {
    name: "وبصادر",
    monthly: 1,
    monthly_confidence: 57.97841670783355,
    weekly: 1,
    weekly_confidence: 61.15143513080353,
  },
  2318736941376687: {
    name: "خصدرا",
    monthly: 1,
    monthly_confidence: 67.36179775009887,
    weekly: 1,
    weekly_confidence: 69.04127792132397,
  },
  "23441366113375722": {
    name: "پارسان",
    monthly: 1,
    monthly_confidence: 73.83039983089488,
    weekly: 1,
    weekly_confidence: 72.2101897901545,
  },
  "70474983732269112": {
    name: "دپارس",
    monthly: 1,
    monthly_confidence: 60.72680563312739,
    weekly: 1,
    weekly_confidence: 71.20280557996594,
  },
  "45452221088910484": {
    name: "عقیق",
    monthly: 1,
    monthly_confidence: 76.4165765196085,
    weekly: 1,
    weekly_confidence: 78.4165765196085,
  },
  "19471788163911687": {
    name: "کفپارس",
    monthly: 0,
    monthly_confidence: 68.631271920229,
    weekly: 0,
    weekly_confidence: 69.631271920229,
  },
  "20966291817819448": {
    name: "فسدید",
    monthly: 1,
    monthly_confidence: 71.25866834742052,
    weekly: 1,
    weekly_confidence: 74.8435004333655,
  },
  "9481703061634967": {
    name: "پارسیان",
    monthly: 1,
    monthly_confidence: 68.43373528179666,
    weekly: 1,
    weekly_confidence: 60.02376303275425,
  },
  28450080638096732: {
    name: "سیمرغ",
    monthly: 1,
    monthly_confidence: 55.88396201541648,
    weekly: 1,
    weekly_confidence: 57.321271372679625,
  },
  8725363201030474: {
    name: "فگستر",
    monthly: 0,
    monthly_confidence: 80.95842614620926,
    weekly: 0,
    weekly_confidence: 83.39959793239832,
  },
  "32347247706508046": {
    name: "ساوه",
    monthly: 1,
    monthly_confidence: 73.35570811189358,
    weekly: 1,
    weekly_confidence: 79.70296240448951,
  },
  778253364357513: {
    name: "وبملت",
    monthly: 1,
    monthly_confidence: 62.377972130881005,
    weekly: 1,
    weekly_confidence: 62.59363143903514,
  },
  "62258804563636993": {
    name: "تکشا",
    monthly: 1,
    monthly_confidence: 66.97123452905743,
    weekly: 1,
    weekly_confidence: 56.315911866476135,
  },
  "59866041653103343": {
    name: "نوین",
    monthly: 0,
    monthly_confidence: 60.3769087522224,
    weekly: 0,
    weekly_confidence: 66.66767672318966,
  },
  "16422980660132735": {
    name: "لطیف",
    monthly: 1,
    monthly_confidence: 79.72431857265268,
    weekly: 1,
    weekly_confidence: 78.23074946403506,
  },
  "10568944722570445": {
    name: "سصفها",
    monthly: 0,
    monthly_confidence: 78.83265707563787,
    weekly: 0,
    weekly_confidence: 68.36060184737048,
  },
  17617474823279712: {
    name: "آ س پ",
    monthly: 0,
    monthly_confidence: 90.38342299650247,
    weekly: 2,
    weekly_confidence: 68.54576468467712,
  },
  "60289595205403229": {
    name: "بهیر",
    monthly: 1,
    monthly_confidence: 78.38421023686361,
    weekly: 2,
    weekly_confidence: 60.30393838882446,
  },
};
const oscillator = {
  17617474823279712: {
    name: "آ س پ",
    label: 0,
    confidence: 58.406692492961895,
  },
  "60289595205403229": {
    name: "بهیر",
    label: 2,
    confidence: 56.664755432059366,
  },
  "68203878405672734": {
    name: "صنوین",
    label: 1,
    confidence: 62.1615494310856,
  },
  "37222720235819361": {
    name: "بذر",
    label: 1,
    confidence: 43.997389561558755,
  },
  10120557300120078: {
    name: "پسهند",
    label: 1,
    confidence: 46.91496209514638,
  },
  "65490886290565185": {
    name: "شزنگ",
    label: 1,
    confidence: 46.43113454369208,
  },
  "33293588228706998": {
    name: "وپارس",
    label: 1,
    confidence: 47.173433410042584,
  },
  "20966291817819448": {
    name: "فسدید",
    label: 1,
    confidence: 60.84350043336551,
  },
  "45452221088910484": {
    name: "عقیق",
    label: 1,
    confidence: 60.41657651960849,
  },
  15521712617204216: {
    name: "سفارس",
    label: 1,
    confidence: 50.283541862231985,
  },
  "9481703061634967": {
    name: "پارسیان",
    label: 1,
    confidence: 50.02376303275425,
  },
  10831074117626896: {
    name: "قاروم",
    label: 1,
    confidence: 60.119641067087635,
  },
  28450080638096732: {
    name: "سیمرغ",
    label: 1,
    confidence: 44.321271372679625,
  },
  "44013656953678055": {
    name: "شرانل",
    label: 1,
    confidence: 66.99777652770281,
  },
  27668158733246204: {
    name: "سپرمی",
    label: 1,
    confidence: 62.83493921508393,
  },
  "19471788163911687": {
    name: "کفپارس",
    label: 0,
    confidence: 54.631271920229,
  },
  "56574323121551263": {
    name: "شکبیر",
    label: 1,
    confidence: 61.32975221524636,
  },
  8725363201030474: {
    name: "فگستر",
    label: 0,
    confidence: 62.39959793239832,
  },
  "45284811973404357": {
    name: "یارا",
    label: 1,
    confidence: 37.210285209243494,
  },
  "53449700212786324": {
    name: "مارون",
    label: 1,
    confidence: 38.36231048386544,
  },
  "71666521540545716": {
    name: "غبهار",
    label: 2,
    confidence: 56.04254602243503,
  },
  17059960254855208: {
    name: "غبهنوش",
    label: 1,
    confidence: 44.434417833760385,
  },
  "67522512921942106": {
    name: "پادا",
    label: 1,
    confidence: 44.98048399627053,
  },
  "32347247706508046": {
    name: "ساوه",
    label: 1,
    confidence: 57.70296240448952,
  },
  "70309338813767186": {
    name: "فکمند",
    label: 1,
    confidence: 63.30461165457963,
  },
  "18865325633315847": {
    name: "خاتم",
    label: 1,
    confidence: 42.65528457028171,
  },
  3050342257199174: {
    name: "قنقش",
    label: 1,
    confidence: 63.77607887275517,
  },
  52455922800537930: {
    name: "تکنار",
    label: 1,
    confidence: 55.43010602841775,
  },
  971068957336171: {
    name: "گارانتی",
    label: 1,
    confidence: 44.32507288432681,
  },
  778253364357513: {
    name: "وبملت",
    label: 1,
    confidence: 49.59363143903514,
  },
  "62258804563636993": {
    name: "تکشا",
    label: 1,
    confidence: 43.315911866476135,
  },
  793710053482057: {
    name: "لپیام",
    label: 1,
    confidence: 40.93881297595799,
  },
  "54369290104873523": {
    name: "بتک",
    label: 1,
    confidence: 59.65818312714497,
  },
  "71672399601682259": {
    name: "ثروتم",
    label: 1,
    confidence: 62.02510097672543,
  },
  "59866041653103343": {
    name: "نوین",
    label: 0,
    confidence: 42.58432538813601,
  },
  "16422980660132735": {
    name: "لطیف",
    label: 1,
    confidence: 60.581070853273076,
  },
  "10568944722570445": {
    name: "سصفها",
    label: 0,
    confidence: 40.91331184320151,
  },
  "28320293733348826": {
    name: "وبصادر",
    label: 1,
    confidence: 48.17508779540658,
  },
  2318736941376687: {
    name: "خصدرا",
    label: 2,
    confidence: 39.99117947970517,
  },
  "23441366113375722": {
    name: "پارسان",
    label: 1,
    confidence: 59.80378210085132,
  },
  "70474983732269112": {
    name: "دپارس",
    label: 1,
    confidence: 48.224336228643864,
  },
  "28431095903407567": {
    name: "کاسپین",
    label: 2,
    confidence: 78.54088554183642,
  },
  3654864906585643: {
    name: "تکنو",
    label: 2,
    confidence: 75.57695503632227,
  },
};
const weeklyMonthlyTest = {
  "68203878405672734": {
    name: "صنوین",
    monthly: 1,
    monthly_confidence: 76.87303518179031,
    weekly: 1,
    weekly_confidence: 66.1591649055481,
    weekly_success: 1,
    monthly_success: 1,
  },
  10120557300120078: {
    name: "پسهند",
    monthly: 1,
    monthly_confidence: 83.57719941204657,
    weekly: 1,
    weekly_confidence: 88.41323256492615,
    weekly_success: 0,
    monthly_success: 1,
  },
  "65490886290565185": {
    name: "شزنگ",
    monthly: 1,
    monthly_confidence: 58.36107688034951,
    weekly: 2,
    weekly_confidence: 50.93839168548584,
    weekly_success: 0,
    monthly_success: 0,
  },
  "44013656953678055": {
    name: "شرانل",
    monthly: 1,
    monthly_confidence: 87.48830820594111,
    weekly: 1,
    weekly_confidence: 89.86008763313293,
    weekly_success: 1,
    monthly_success: 1,
  },
  "45284811973404357": {
    name: "یارا",
    monthly: 1,
    monthly_confidence: 58.27309912469541,
    weekly: 1,
    weekly_confidence: 90.74680209159851,
    weekly_success: 1,
    monthly_success: 1,
  },
  "18865325633315847": {
    name: "خاتم",
    monthly: 1,
    monthly_confidence: 63.953938424572364,
    weekly: 1,
    weekly_confidence: 71.97185754776001,
    weekly_success: 1,
    monthly_success: 1,
  },
  52455922800537930: {
    name: "تکنار",
    monthly: 1,
    monthly_confidence: 64.1020702648118,
    weekly: 1,
    weekly_confidence: 85.4066252708435,
    weekly_success: 1,
    monthly_success: 1,
  },
  971068957336171: {
    name: "گارانتی",
    monthly: 1,
    monthly_confidence: 52.063673363480625,
    weekly: 1,
    weekly_confidence: 76.41268968582153,
    weekly_success: 1,
    monthly_success: 1,
  },
  "54369290104873523": {
    name: "بتک",
    monthly: 1,
    monthly_confidence: 74.79818867564433,
    weekly: 1,
    weekly_confidence: 71.51387333869934,
    weekly_success: 1,
    monthly_success: 1,
  },
  "56574323121551263": {
    name: "شکبیر",
    monthly: 1,
    monthly_confidence: 69.55209444297263,
    weekly: 1,
    weekly_confidence: 75.29126405715942,
    weekly_success: 1,
    monthly_success: 1,
  },
  27668158733246204: {
    name: "سپرمی",
    monthly: 1,
    monthly_confidence: 68.6832476090438,
    weekly: 1,
    weekly_confidence: 59.93548035621643,
    weekly_success: 1,
    monthly_success: 1,
  },
  10831074117626896: {
    name: "قاروم",
    monthly: 1,
    monthly_confidence: 89.09134272615927,
    weekly: 1,
    weekly_confidence: 51.51593089103699,
    weekly_success: 1,
    monthly_success: 1,
  },
  3050342257199174: {
    name: "قنقش",
    monthly: 1,
    monthly_confidence: 76.08426901956807,
    weekly: 1,
    weekly_confidence: 75.36104321479797,
    weekly_success: 1,
    monthly_success: 0,
  },
  793710053482057: {
    name: "لپیام",
    monthly: 1,
    monthly_confidence: 65.34996756941491,
    weekly: 1,
    weekly_confidence: 86.3107681274414,
    weekly_success: 1,
    monthly_success: 0,
  },
  "37222720235819361": {
    name: "بذر",
    monthly: 1,
    monthly_confidence: 63.547939315177906,
    weekly: 1,
    weekly_confidence: 65.0871753692627,
    weekly_success: 1,
    monthly_success: 1,
  },
  "33293588228706998": {
    name: "وپارس",
    monthly: 1,
    monthly_confidence: 58.42516059829896,
    weekly: 1,
    weekly_confidence: 75.90320110321045,
    weekly_success: 1,
    monthly_success: 1,
  },
  15521712617204216: {
    name: "سفارس",
    monthly: 1,
    monthly_confidence: 69.77583878686713,
    weekly: 1,
    weekly_confidence: 94.52303051948547,
    weekly_success: 1,
    monthly_success: 1,
  },
  "53449700212786324": {
    name: "مارون",
    monthly: 1,
    monthly_confidence: 55.743093268733624,
    weekly: 1,
    weekly_confidence: 74.42657947540283,
    weekly_success: 1,
    monthly_success: 1,
  },
  "71666521540545716": {
    name: "غبهار",
    monthly: 2,
    monthly_confidence: 78.58616625097721,
    weekly: 1,
    weekly_confidence: 77.70861983299255,
    weekly_success: 0,
    monthly_success: 1,
  },
  17059960254855208: {
    name: "غبهنوش",
    monthly: 1,
    monthly_confidence: 60.42017672570582,
    weekly: 1,
    weekly_confidence: 90.2481198310852,
    weekly_success: 1,
    monthly_success: 1,
  },
  "67522512921942106": {
    name: "پادا",
    monthly: 1,
    monthly_confidence: 78.59135849423949,
    weekly: 1,
    weekly_confidence: 76.8059492111206,
    weekly_success: 1,
    monthly_success: 1,
  },
  "70309338813767186": {
    name: "فکمند",
    monthly: 1,
    monthly_confidence: 83.76580948797262,
    weekly: 1,
    weekly_confidence: 85.97419261932373,
    weekly_success: 1,
    monthly_success: 1,
  },
  "71672399601682259": {
    name: "ثروتم",
    monthly: 1,
    monthly_confidence: 76.11028088870819,
    weekly: 1,
    weekly_confidence: 56.98431730270386,
    weekly_success: 1,
    monthly_success: 1,
  },
  "28320293733348826": {
    name: "وبصادر",
    monthly: 1,
    monthly_confidence: 59.435125840086926,
    weekly: 1,
    weekly_confidence: 65.2501106262207,
    weekly_success: 1,
    monthly_success: 1,
  },
  2318736941376687: {
    name: "خصدرا",
    monthly: 1,
    monthly_confidence: 53.647865301004884,
    weekly: 1,
    weekly_confidence: 52.69916653633118,
    weekly_success: 1,
    monthly_success: 0,
  },
  "23441366113375722": {
    name: "پارسان",
    monthly: 1,
    monthly_confidence: 62.52178044682745,
    weekly: 1,
    weekly_confidence: 91.36760830879211,
    weekly_success: 1,
    monthly_success: 1,
  },
  "70474983732269112": {
    name: "دپارس",
    monthly: 1,
    monthly_confidence: 64.61822519932082,
    weekly: 1,
    weekly_confidence: 75.17162561416626,
    weekly_success: 1,
    monthly_success: 1,
  },
  "45452221088910484": {
    name: "عقیق",
    monthly: 1,
    monthly_confidence: 81.30550351226819,
    weekly: 1,
    weekly_confidence: 92.9349422454834,
    weekly_success: 1,
    monthly_success: 1,
  },
  "19471788163911687": {
    name: "کفپارس",
    monthly: 0,
    monthly_confidence: 77.04040082081065,
    weekly: 1,
    weekly_confidence: 50.17576813697815,
    weekly_success: 1,
    monthly_success: 1,
  },
  "20966291817819448": {
    name: "فسدید",
    monthly: 1,
    monthly_confidence: 78.52982986362655,
    weekly: 1,
    weekly_confidence: 67.00130105018616,
    weekly_success: 1,
    monthly_success: 1,
  },
};
const oscillatorTest = {
  "68203878405672734": {
    name: "صنوین",
    label: 1,
    confidence: 62.1615494310856,
    success: 1,
  },
  "37222720235819361": {
    name: "بذر",
    label: 1,
    confidence: 43.997389561558755,
    success: 1,
  },
  10120557300120078: {
    name: "پسهند",
    label: 1,
    confidence: 46.91496209514638,
    success: 0,
  },
  "65490886290565185": {
    name: "شزنگ",
    label: 1,
    confidence: 46.43113454369208,
    success: 1,
  },
  "33293588228706998": {
    name: "وپارس",
    label: 1,
    confidence: 47.173433410042584,
    success: 1,
  },
  "20966291817819448": {
    name: "فسدید",
    label: 1,
    confidence: 60.84350043336551,
    success: 1,
  },
  "45452221088910484": {
    name: "عقیق",
    label: 1,
    confidence: 60.41657651960849,
    success: 1,
  },
  15521712617204216: {
    name: "سفارس",
    label: 1,
    confidence: 50.283541862231985,
    success: 1,
  },
  "9481703061634967": {
    name: "پارسیان",
    label: 1,
    confidence: 50.02376303275425,
    success: 0,
  },
  10831074117626896: {
    name: "قاروم",
    label: 1,
    confidence: 60.119641067087635,
    success: 1,
  },
  28450080638096732: {
    name: "سیمرغ",
    label: 1,
    confidence: 44.321271372679625,
    success: 1,
  },
  "44013656953678055": {
    name: "شرانل",
    label: 1,
    confidence: 66.99777652770281,
    success: 1,
  },
  27668158733246204: {
    name: "سپرمی",
    label: 1,
    confidence: 62.83493921508393,
    success: 1,
  },
  "19471788163911687": {
    name: "کفپارس",
    label: 0,
    confidence: 54.631271920229,
    success: 0,
  },
  "56574323121551263": {
    name: "شکبیر",
    label: 1,
    confidence: 61.32975221524636,
    success: 1,
  },
  8725363201030474: {
    name: "فگستر",
    label: 0,
    confidence: 62.39959793239832,
    success: 1,
  },
  "45284811973404357": {
    name: "یارا",
    label: 1,
    confidence: 37.210285209243494,
    success: 1,
  },
  "53449700212786324": {
    name: "مارون",
    label: 1,
    confidence: 38.36231048386544,
    success: 1,
  },
  "71666521540545716": {
    name: "غبهار",
    label: 2,
    confidence: 56.04254602243503,
    success: 0,
  },
  17059960254855208: {
    name: "غبهنوش",
    label: 1,
    confidence: 44.434417833760385,
    success: 1,
  },
  "67522512921942106": {
    name: "پادا",
    label: 1,
    confidence: 44.98048399627053,
    success: 1,
  },
  "32347247706508046": {
    name: "ساوه",
    label: 1,
    confidence: 57.70296240448952,
    success: 1,
  },
  "70309338813767186": {
    name: "فکمند",
    label: 1,
    confidence: 63.30461165457963,
    success: 1,
  },
  "18865325633315847": {
    name: "خاتم",
    label: 1,
    confidence: 42.65528457028171,
    success: 1,
  },
  3050342257199174: {
    name: "قنقش",
    label: 1,
    confidence: 63.77607887275517,
    success: 0,
  },
  52455922800537930: {
    name: "تکنار",
    label: 1,
    confidence: 55.43010602841775,
    success: 1,
  },
  971068957336171: {
    name: "گارانتی",
    label: 1,
    confidence: 44.32507288432681,
    success: 1,
  },
  778253364357513: {
    name: "وبملت",
    label: 1,
    confidence: 49.59363143903514,
    success: 1,
  },
  "62258804563636993": {
    name: "تکشا",
    label: 1,
    confidence: 43.315911866476135,
    success: 0,
  },
  793710053482057: {
    name: "لپیام",
    label: 1,
    confidence: 40.93881297595799,
    success: 1,
  },
  "54369290104873523": {
    name: "بتک",
    label: 1,
    confidence: 59.65818312714497,
    success: 1,
  },
  "71672399601682259": {
    name: "ثروتم",
    label: 1,
    confidence: 62.02510097672543,
    success: 1,
  },
  "59866041653103343": {
    name: "نوین",
    label: 0,
    confidence: 42.58432538813601,
    success: 0,
  },
  "16422980660132735": {
    name: "لطیف",
    label: 1,
    confidence: 60.581070853273076,
    success: 1,
  },
  "10568944722570445": {
    name: "سصفها",
    label: 0,
    confidence: 40.91331184320151,
    success: 0,
  },
  "28320293733348826": {
    name: "وبصادر",
    label: 1,
    confidence: 48.17508779540658,
    success: 1,
  },
  2318736941376687: {
    name: "خصدرا",
    label: 2,
    confidence: 39.99117947970517,
    success: 0,
  },
  "23441366113375722": {
    name: "پارسان",
    label: 1,
    confidence: 59.80378210085132,
    success: 1,
  },
  "70474983732269112": {
    name: "دپارس",
    label: 1,
    confidence: 48.224336228643864,
    success: 0,
  },
  1: {
    name: "کل بورس",
    label: 0,
    confidence: 48.02706692417462,
    success: 0,
  },
  57: {
    name: "کل هم وزن",
    label: 0,
    confidence: 58.96364462698499,
    success: 0,
  },
};

export const weeklyData = Object.entries(weeklyMonthly)
  .map(([key, value], index) => ({ ...value, index: index + 1 }))
  .filter((item) => item.weekly_confidence >= 50)
  .sort((a, b) => b.weekly_confidence - a.weekly_confidence);

// .filter((item) => item.weekly_confidence >= 60)

export const monthlyData = Object.entries(weeklyMonthly)
  .map(([key, value], index) => ({ ...value, index: index + 1 }))
  .filter((item) => item.monthly_confidence >= 50)
  .sort((a, b) => b.monthly_confidence - a.monthly_confidence);

export const oscillationData = Object.entries(oscillator)
  .map(([key, value], index) => ({ ...value, index: index + 1 }))
  .sort((a, b) => b.confidence - a.confidence);

// .filter((item) => item.confidence >= 50)

export const weeklyTestData = Object.entries(weeklyMonthlyTest)
  .map(([key, value], index) => ({ ...value, index: index + 1 }))
  .sort((a, b) => b.weekly_confidence - a.weekly_confidence);

export const monthlyTestData = Object.entries(weeklyMonthlyTest)
  .map(([key, value], index) => ({ ...value, index: index + 1 }))
  .sort((a, b) => b.monthly_confidence - a.monthly_confidence);

export const oscillationTestData = Object.entries(oscillatorTest)
  .map(([key, value], index) => ({ ...value, index: index + 1 }))
  .sort((a, b) => b.confidence - a.confidence);

export const oscillationAlan = [
  {
    name: "شرنگی",
    confidence: 69.66572114527226,
    label: 2,
  },
  {
    name: "زماهان",
    confidence: 68.10820300678412,
    label: 0,
  },
  {
    name: "کیا",
    confidence: 67.99045296857754,
    label: 2,
  },
  {
    name: "نگین",
    confidence: 67.31553155779838,
    label: 1,
  },
  {
    name: "شفارا",
    confidence: 66.09037436296542,
    label: 2,
  },
  {
    name: "قنقش",
    confidence: 65.07151624461015,
    label: 1,
  },
  {
    name: "دامین",
    confidence: 64.77240933875242,
    label: 0,
  },
  {
    name: "ثتوسا",
    confidence: 64.74597730288903,
    label: 1,
  },
  {
    name: "ثزاگرس",
    confidence: 64.38506444295248,
    label: 1,
  },
  {
    name: "فگستر",
    confidence: 63.892107651631036,
    label: 0,
  },
  {
    name: "قاروم",
    confidence: 63.404968818525475,
    label: 1,
  },
  {
    name: "پارسان",
    confidence: 62.93349519073964,
    label: 1,
  },
  {
    name: "صنوین",
    confidence: 61.35465375532706,
    label: 1,
  },
  {
    name: "شستا",
    confidence: 60.12307609990239,
    label: 1,
  },
  {
    name: "وجامی",
    confidence: 59.71287642916043,
    label: 0,
  },
  {
    name: "شدوص",
    confidence: 58.381996720035865,
    label: 2,
  },
  {
    name: "جم پیلن",
    confidence: 57.76075403342645,
    label: 1,
  },
  {
    name: "شکبیر",
    confidence: 56.93669309193889,
    label: 1,
  },
  {
    name: "اتکام",
    confidence: 56.405357710768776,
    label: 2,
  },
  {
    name: "سفاسی",
    confidence: 56.36095229545608,
    label: 2,
  },
  {
    name: "تکنار",
    confidence: 55.582002071291214,
    label: 1,
  },
  {
    name: "سیمرغ",
    confidence: 54.802457951940596,
    label: 1,
  },
  {
    name: "عقیق",
    confidence: 54.332542682687446,
    label: 2,
  },
  {
    name: "فسدید",
    confidence: 53.799819232026735,
    label: 1,
  },
  {
    name: "وپترو",
    confidence: 53.11218175639708,
    label: 1,
  },
  {
    name: "شرانل",
    confidence: 52.79623101577163,
    label: 1,
  },
  {
    name: "سمتاز",
    confidence: 52.35021938135226,
    label: 1,
  },
  {
    name: "پادا",
    confidence: 52.14047012527784,
    label: 1,
  },
  {
    name: "سفارس",
    confidence: 52.123648701173565,
    label: 1,
  },
  {
    name: "کفپارس",
    confidence: 51.972040379544104,
    label: 0,
  },
  {
    name: "دکپسول",
    confidence: 51.45832904254396,
    label: 2,
  },
  {
    name: "سکارون",
    confidence: 51.452848548938825,
    label: 2,
  },
  {
    name: "لطیف",
    confidence: 51.389905107859526,
    label: 1,
  },
  {
    name: "فکمند",
    confidence: 50.87516257977113,
    label: 1,
  },
  {
    name: "وصنا",
    confidence: 50.770156198771055,
    label: 0,
  },
  {
    name: "دتماد",
    confidence: 50.568092344825466,
    label: 1,
  },
  {
    name: "سپرمی",
    confidence: 50.5219113809367,
    label: 1,
  },
  {
    name: "حسیر",
    confidence: 50.49845539120336,
    label: 2,
  },
  {
    name: "کرمان",
    confidence: 49.46266751637062,
    label: 0,
  },
  {
    name: "داسوه",
    confidence: 49.20398479847548,
    label: 2,
  },
  {
    name: "واعتبار",
    confidence: 48.91940760612488,
    label: 0,
  },
  {
    name: "بذر",
    confidence: 48.8397352412343,
    label: 1,
  },
  {
    name: "ساوه",
    confidence: 48.665317996715494,
    label: 1,
  },
  {
    name: "کمند",
    confidence: 48.45430680647648,
    label: 1,
  },
  {
    name: "غبهار",
    confidence: 48.10812064185739,
    label: 2,
  },
  {
    name: "وتوس",
    confidence: 47.969160106281436,
    label: 0,
  },
  {
    name: "حفارس",
    confidence: 47.716043038790424,
    label: 0,
  },
  {
    name: "ختوقا",
    confidence: 47.4660391194125,
    label: 0,
  },
  {
    name: "وبملت",
    confidence: 47.15636968081818,
    label: 1,
  },
  {
    name: "گارانتی",
    confidence: 45.81963271608498,
    label: 1,
  },
  {
    name: "خصدرا",
    confidence: 45.7941240115712,
    label: 2,
  },
  {
    name: "وپارس",
    confidence: 45.78881895292822,
    label: 1,
  },
  {
    name: "لپیام",
    confidence: 45.720156048983334,
    label: 1,
  },
  {
    name: "نوین",
    confidence: 45.640799114666876,
    label: 0,
  },
  {
    name: "غبهنوش",
    confidence: 45.26943787653,
    label: 1,
  },
  {
    name: "کماسه",
    confidence: 45.18953549880535,
    label: 1,
  },
  {
    name: "دپارس",
    confidence: 45.15090518395106,
    label: 1,
  },
  {
    name: "نیلی",
    confidence: 45.04005154199355,
    label: 1,
  },
  {
    name: "شپدیس",
    confidence: 44.579248488011466,
    label: 1,
  },
  {
    name: "کسرام",
    confidence: 44.396224454573044,
    label: 1,
  },
  {
    name: "هامرز",
    confidence: 43.70872329916262,
    label: 1,
  },
  {
    name: "خزامیا",
    confidence: 43.62417464107274,
    label: 0,
  },
  {
    name: "آتیمس",
    confidence: 42.839337161680064,
    label: 1,
  },
  {
    name: "خاتم",
    confidence: 42.58384248921648,
    label: 1,
  },
  {
    name: "تاتمس",
    confidence: 42.51407884707053,
    label: 1,
  },
  {
    name: "مارون",
    confidence: 42.315857941160594,
    label: 1,
  },
  {
    name: "بتک",
    confidence: 42.05717961927875,
    label: 1,
  },
  {
    name: "موج",
    confidence: 40.923863000407195,
    label: 2,
  },
  {
    name: "سصفها",
    confidence: 40.40652607325465,
    label: 2,
  },
  {
    name: "شزنگ",
    confidence: 40.00823927695552,
    label: 1,
  },
  {
    name: "پارسیان",
    confidence: 39.35250791058837,
    label: 2,
  },
  {
    name: "تکشا",
    confidence: 38.32953202178081,
    label: 1,
  },
  {
    name: "ثروتم",
    confidence: 38.12865319241149,
    label: 1,
  },
  {
    name: "کاردان",
    confidence: 37.063042611815035,
    label: 2,
  },
  {
    name: "پسهند",
    confidence: 36.762792691215864,
    label: 1,
  },
  {
    name: "وبصادر",
    confidence: 36.690966759901485,
    label: 0,
  },
  {
    name: "چخزر",
    confidence: 36.66148798642681,
    label: 1,
  },
  {
    name: "الماس",
    confidence: 36.27747469395399,
    label: 1,
  },
  {
    name: "یارا",
    confidence: 35.943100486948005,
    label: 1,
  },
];
export const weeklyAlan = [
  {
    name: "سفارس",
    confidence: 94.10854578018188,
    label: 1,
  },
  {
    name: "غبهنوش",
    confidence: 92.75725483894348,
    label: 1,
  },
  {
    name: "یارا",
    confidence: 90.74680209159851,
    label: 1,
  },
  {
    name: "تکنار",
    confidence: 89.43523168563843,
    label: 1,
  },
  {
    name: "سمتاز",
    confidence: 89.35631513595581,
    label: 1,
  },
  {
    name: "شکبیر",
    confidence: 86.10320091247559,
    label: 1,
  },
  {
    name: "بتک",
    confidence: 84.82698798179626,
    label: 1,
  },
  {
    name: "شرنگی",
    confidence: 84.57843661308289,
    label: 1,
  },
  {
    name: "نیلی",
    confidence: 83.70146751403809,
    label: 1,
  },
  {
    name: "ساوه",
    confidence: 83.27265977859497,
    label: 1,
  },
  {
    name: "کمند",
    confidence: 82.77742266654968,
    label: 1,
  },
  {
    name: "شرانل",
    confidence: 81.80705904960632,
    label: 1,
  },
  {
    name: "جم پیلن",
    confidence: 81.19890093803406,
    label: 1,
  },
  {
    name: "وپترو",
    confidence: 80.52610158920288,
    label: 1,
  },
  {
    name: "هامرز",
    confidence: 80.27799129486084,
    label: 1,
  },
  {
    name: "فکمند",
    confidence: 78.82789969444275,
    label: 1,
  },
  {
    name: "دپارس",
    confidence: 78.43793630599976,
    label: 1,
  },
  {
    name: "گارانتی",
    confidence: 78.08811664581299,
    label: 1,
  },
  {
    name: "پارسان",
    confidence: 76.48326754570007,
    label: 1,
  },
  {
    name: "تاتمس",
    confidence: 74.52149391174316,
    label: 1,
  },
  {
    name: "وتوس",
    confidence: 74.47658181190491,
    label: 1,
  },
  {
    name: "پسهند",
    confidence: 73.34783673286438,
    label: 1,
  },
  {
    name: "غبهار",
    confidence: 73.08153510093689,
    label: 1,
  },
  {
    name: "الماس",
    confidence: 72.92348742485046,
    label: 1,
  },
  {
    name: "وصنا",
    confidence: 72.08061814308167,
    label: 1,
  },
  {
    name: "ثروتم",
    confidence: 72.0353364944458,
    label: 1,
  },
  {
    name: "خاتم",
    confidence: 71.97185754776001,
    label: 1,
  },
  {
    name: "داسوه",
    confidence: 71.28828763961792,
    label: 1,
  },
  {
    name: "مارون",
    confidence: 70.94163298606873,
    label: 1,
  },
  {
    name: "پادا",
    confidence: 70.46831250190735,
    label: 1,
  },
  {
    name: "ثزاگرس",
    confidence: 70.13932466506958,
    label: 1,
  },
  {
    name: "شستا",
    confidence: 69.69725489616394,
    label: 1,
  },
  {
    name: "قاروم",
    confidence: 67.79229640960693,
    label: 1,
  },
  {
    name: "فسدید",
    confidence: 67.38263964653015,
    label: 1,
  },
  {
    name: "سیمرغ",
    confidence: 66.25040173530579,
    label: 1,
  },
  {
    name: "حسیر",
    confidence: 66.09148979187012,
    label: 1,
  },
  {
    name: "کفپارس",
    confidence: 65.59599041938782,
    label: 1,
  },
  {
    name: "موج",
    confidence: 64.962899684906,
    label: 1,
  },
  {
    name: "سفاسی",
    confidence: 64.54681158065796,
    label: 1,
  },
  {
    name: "شفارا",
    confidence: 64.48934674263,
    label: 2,
  },
  {
    name: "لپیام",
    confidence: 63.974058628082275,
    label: 1,
  },
  {
    name: "دکپسول",
    confidence: 63.2259726524353,
    label: 2,
  },
  {
    name: "بذر",
    confidence: 63.002949953079224,
    label: 1,
  },
  {
    name: "وپارس",
    confidence: 61.50211691856384,
    label: 1,
  },
  {
    name: "ختوقا",
    confidence: 61.05019450187683,
    label: 1,
  },
  {
    name: "لطیف",
    confidence: 60.31202673912048,
    label: 1,
  },
  {
    name: "صنوین",
    confidence: 60.04762649536133,
    label: 1,
  },
  {
    name: "سپرمی",
    confidence: 59.740883111953735,
    label: 1,
  },
  {
    name: "وبصادر",
    confidence: 59.441643953323364,
    label: 1,
  },
  {
    name: "فگستر",
    confidence: 59.25687551498413,
    label: 1,
  },
  {
    name: "دامین",
    confidence: 58.821433782577515,
    label: 1,
  },
  {
    name: "کسرام",
    confidence: 58.44467878341675,
    label: 2,
  },
  {
    name: "وبملت",
    confidence: 57.584720849990845,
    label: 1,
  },
  {
    name: "چخزر",
    confidence: 57.08693861961365,
    label: 1,
  },
  {
    name: "خزامیا",
    confidence: 56.6897988319397,
    label: 1,
  },
  {
    name: "خصدرا",
    confidence: 56.471580266952515,
    label: 1,
  },
  {
    name: "سکارون",
    confidence: 56.33166432380676,
    label: 2,
  },
  {
    name: "وجامی",
    confidence: 56.040626764297485,
    label: 1,
  },
  {
    name: "واعتبار",
    confidence: 55.761611461639404,
    label: 2,
  },
  {
    name: "تکشا",
    confidence: 54.97024059295654,
    label: 1,
  },
  {
    name: "عقیق",
    confidence: 53.919267654418945,
    label: 2,
  },
  {
    name: "نوین",
    confidence: 52.71214246749878,
    label: 1,
  },
  {
    name: "ثتوسا",
    confidence: 52.5104820728302,
    label: 0,
  },
  {
    name: "شپدیس",
    confidence: 52.40470767021179,
    label: 2,
  },
  {
    name: "حفارس",
    confidence: 51.133936643600464,
    label: 1,
  },
  {
    name: "کاردان",
    confidence: 50.10154843330383,
    label: 1,
  },
  {
    name: "شزنگ",
    confidence: 48.96497428417206,
    label: 2,
  },
  {
    name: "کرمان",
    confidence: 48.807355761528015,
    label: 1,
  },
  {
    name: "پارسیان",
    confidence: 48.5998272895813,
    label: 1,
  },
  {
    name: "قنقش",
    confidence: 48.10863733291626,
    label: 1,
  },
  {
    name: "کماسه",
    confidence: 48.019570112228394,
    label: 2,
  },
  {
    name: "دتماد",
    confidence: 47.79114127159119,
    label: 1,
  },
  {
    name: "نگین",
    confidence: 46.78490459918976,
    label: 0,
  },
  {
    name: "اتکام",
    confidence: 46.461474895477295,
    label: 1,
  },
  {
    name: "سصفها",
    confidence: 46.28234803676605,
    label: 1,
  },
  {
    name: "آتیمس",
    confidence: 44.73806917667389,
    label: 1,
  },
  {
    name: "شدوص",
    confidence: 44.580256938934326,
    label: 1,
  },
  {
    name: "زماهان",
    confidence: 40.95720052719116,
    label: 0,
  },
  {
    name: "کیا",
    confidence: 38.63501846790314,
    label: 1,
  },
];
export const monthlyAlan = [
  {
    name: "قاروم",
    confidence: 99.96592998504639,
    label: 1,
  },
  {
    name: "عقیق",
    confidence: 99.96507167816162,
    label: 1,
  },
  {
    name: "تکنار",
    confidence: 99.9563992023468,
    label: 1,
  },
  {
    name: "چخزر",
    confidence: 99.95115995407104,
    label: 1,
  },
  {
    name: "فسدید",
    confidence: 99.94314312934875,
    label: 1,
  },
  {
    name: "لپیام",
    confidence: 99.93873834609985,
    label: 1,
  },
  {
    name: "مارون",
    confidence: 99.9312162399292,
    label: 1,
  },
  {
    name: "غبهنوش",
    confidence: 99.92586970329285,
    label: 1,
  },
  {
    name: "تاتمس",
    confidence: 99.92550015449524,
    label: 1,
  },
  {
    name: "الماس",
    confidence: 99.9127209186554,
    label: 1,
  },
  {
    name: "کمند",
    confidence: 99.910968542099,
    label: 1,
  },
  {
    name: "شکبیر",
    confidence: 99.87567663192749,
    label: 1,
  },
  {
    name: "شزنگ",
    confidence: 99.84700083732605,
    label: 1,
  },
  {
    name: "سپرمی",
    confidence: 99.84394907951355,
    label: 1,
  },
  {
    name: "پادا",
    confidence: 99.82118606567383,
    label: 1,
  },
  {
    name: "هامرز",
    confidence: 99.75987076759338,
    label: 1,
  },
  {
    name: "شپدیس",
    confidence: 99.73951578140259,
    label: 1,
  },
  {
    name: "دتماد",
    confidence: 99.72957968711853,
    label: 1,
  },
  {
    name: "پارسان",
    confidence: 99.7005045413971,
    label: 1,
  },
  {
    name: "نیلی",
    confidence: 99.60427284240723,
    label: 1,
  },
  {
    name: "حفارس",
    confidence: 99.60067868232727,
    label: 1,
  },
  {
    name: "کرمان",
    confidence: 99.59999322891235,
    label: 1,
  },
  {
    name: "تکشا",
    confidence: 99.56939220428467,
    label: 1,
  },
  {
    name: "سفاسی",
    confidence: 99.51855540275574,
    label: 1,
  },
  {
    name: "حسیر",
    confidence: 99.51785802841187,
    label: 1,
  },
  {
    name: "دپارس",
    confidence: 99.45515990257263,
    label: 1,
  },
  {
    name: "نوین",
    confidence: 99.45511221885681,
    label: 1,
  },
  {
    name: "وبصادر",
    confidence: 99.39538836479187,
    label: 1,
  },
  {
    name: "کماسه",
    confidence: 99.37445521354675,
    label: 1,
  },
  {
    name: "خصدرا",
    confidence: 99.35626983642578,
    label: 1,
  },
  {
    name: "داسوه",
    confidence: 99.32681918144226,
    label: 1,
  },
  {
    name: "شستا",
    confidence: 99.10757541656494,
    label: 1,
  },
  {
    name: "کفپارس",
    confidence: 99.0054726600647,
    label: 1,
  },
  {
    name: "لطیف",
    confidence: 98.7368643283844,
    label: 1,
  },
  {
    name: "قنقش",
    confidence: 98.65021705627441,
    label: 1,
  },
  {
    name: "بتک",
    confidence: 98.65012764930725,
    label: 1,
  },
  {
    name: "وپارس",
    confidence: 98.64296913146973,
    label: 1,
  },
  {
    name: "ختوقا",
    confidence: 98.52650761604309,
    label: 1,
  },
  {
    name: "سمتاز",
    confidence: 98.4683096408844,
    label: 1,
  },
  {
    name: "وبملت",
    confidence: 98.39872121810913,
    label: 1,
  },
  {
    name: "یارا",
    confidence: 98.32004308700562,
    label: 1,
  },
  {
    name: "نگین",
    confidence: 98.14576506614685,
    label: 1,
  },
  {
    name: "سیمرغ",
    confidence: 98.1033205986023,
    label: 1,
  },
  {
    name: "اتکام",
    confidence: 97.91862368583679,
    label: 1,
  },
  {
    name: "سفارس",
    confidence: 97.50186204910278,
    label: 1,
  },
  {
    name: "دکپسول",
    confidence: 96.78327441215515,
    label: 1,
  },
  {
    name: "گارانتی",
    confidence: 96.62971496582031,
    label: 1,
  },
  {
    name: "خاتم",
    confidence: 96.34295701980591,
    label: 1,
  },
  {
    name: "وتوس",
    confidence: 96.27445340156555,
    label: 1,
  },
  {
    name: "سصفها",
    confidence: 96.22287154197693,
    label: 1,
  },
  {
    name: "غبهار",
    confidence: 96.123206615448,
    label: 1,
  },
  {
    name: "شرانل",
    confidence: 96.11942172050476,
    label: 1,
  },
  {
    name: "وصنا",
    confidence: 95.96859216690063,
    label: 1,
  },
  {
    name: "خزامیا",
    confidence: 95.91467380523682,
    label: 1,
  },
  {
    name: "آتیمس",
    confidence: 95.77648043632507,
    label: 1,
  },
  {
    name: "شدوص",
    confidence: 95.15740871429443,
    label: 1,
  },
  {
    name: "فگستر",
    confidence: 94.89369988441467,
    label: 1,
  },
  {
    name: "پارسیان",
    confidence: 94.42288875579834,
    label: 1,
  },
  {
    name: "پسهند",
    confidence: 94.17626857757568,
    label: 1,
  },
  {
    name: "سکارون",
    confidence: 94.16480660438538,
    label: 1,
  },
  {
    name: "موج",
    confidence: 94.03892755508423,
    label: 1,
  },
  {
    name: "ثزاگرس",
    confidence: 92.1298086643219,
    label: 1,
  },
  {
    name: "شفارا",
    confidence: 87.18785047531128,
    label: 1,
  },
  {
    name: "شرنگی",
    confidence: 86.30649447441101,
    label: 1,
  },
  {
    name: "بذر",
    confidence: 83.92839431762695,
    label: 1,
  },
  {
    name: "ثروتم",
    confidence: 82.08305835723877,
    label: 1,
  },
  {
    name: "ثتوسا",
    confidence: 79.90626096725464,
    label: 1,
  },
  {
    name: "وپترو",
    confidence: 78.54956388473511,
    label: 1,
  },
  {
    name: "فکمند",
    confidence: 77.16866135597229,
    label: 1,
  },
  {
    name: "زماهان",
    confidence: 75.83284974098206,
    label: 0,
  },
  {
    name: "جم پیلن",
    confidence: 71.47042751312256,
    label: 1,
  },
  {
    name: "کسرام",
    confidence: 69.40249800682068,
    label: 1,
  },
  {
    name: "واعتبار",
    confidence: 67.95060634613037,
    label: 1,
  },
  {
    name: "کاردان",
    confidence: 61.994218826293945,
    label: 1,
  },
  {
    name: "وجامی",
    confidence: 61.98038458824158,
    label: 1,
  },
  {
    name: "کیا",
    confidence: 59.26252603530884,
    label: 0,
  },
  {
    name: "صنوین",
    confidence: 56.021976470947266,
    label: 1,
  },
  {
    name: "دامین",
    confidence: 55.96463680267334,
    label: 1,
  },
  {
    name: "ساوه",
    confidence: 55.73141574859619,
    label: 1,
  },
];

export const fundamentalData = [
  {
    stockTitle: "فملی",
    pure_profit: 580062345,
    eps: 744,
    pe: 9.23169267707083,
    ps: 6.78774372528757,
    roa: 0.306197680838496,
    pure_profit_margin: 0.468054633824619,
  },
  {
    stockTitle: "کاوه",
    pure_profit: 65033791,
    eps: 2036,
    pe: 4.22888015717092,
    ps: 0.825054499952411,
    roa: 0.21751283782728,
    pure_profit_margin: 0.194746014494464,
  },
  {
    stockTitle: "سشرق",
    pure_profit: 698029,
    eps: 263,
    pe: 5.33999231655782,
    ps: 3.71606695280665,
    roa: 0.0369341759266799,
    pure_profit_margin: 0.0996584341940438,
  },
  {
    stockTitle: "سابیک",
    pure_profit: 12612978,
    eps: 4262,
    pe: 6.69638667292351,
    ps: 2.81995095946535,
    roa: 0.457820412415859,
    pure_profit_margin: 0.421129906899845,
  },
  {
    stockTitle: "کگل",
    pure_profit: 180907471,
    eps: 476,
    pe: 7.41176470588235,
    ps: 2.21271762782143,
    roa: 0.147307241834355,
    pure_profit_margin: 0.263867234935331,
  },
  {
    stockTitle: "کنور",
    pure_profit: 34812198,
    eps: 2726,
    pe: 2.34409391049156,
    ps: 4.38234633761966,
    roa: 0.344060582228593,
    pure_profit_margin: 0.607497833406167,
  },
  {
    stockTitle: "شتران",
    pure_profit: 252139570,
    eps: 647,
    pe: 2.52665441176471,
    ps: 0.487009406008117,
    roa: 0.321547086029464,
    pure_profit_margin: 0.111064516851802,
  },
  {
    stockTitle: "شپنا",
    pure_profit: 378151904,
    eps: 1019,
    pe: 3.5069008782936,
    ps: 0.610193540528058,
    roa: 0.334807781069668,
    pure_profit_margin: 0.0927085690933492,
  },
  {
    stockTitle: "شیراز",
    pure_profit: 55862191,
    eps: 3651,
    pe: 6.37359627499315,
    ps: 1.63912271772496,
    roa: 0.188056917081888,
    pure_profit_margin: 0.257182622664854,
  },
  {
    stockTitle: "زاگرس",
    pure_profit: 13254898,
    eps: 5523,
    pe: 19.4006880318667,
    ps: 0.993913339793049,
    roa: 0.0789687703783295,
    pure_profit_margin: 0.0512296622328364,
  },
];
