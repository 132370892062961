import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../variables.js";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LoadingGif from "../assets/loading.gif";
import PredictSearch from "./predictSearch.jsx";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import PickerPredict from "./pickerPredict.jsx";

function OscillationPredict() {
  const [thisTableInitial, setThisTableInitial] = useState();
  const [thisTable, setThisTable] = useState();
  const [thisTableTest, setThisTableTest] = useState();
  const [loading, setLoading] = useState(true);
  const [requestedDate, setRequestedDate] = useState("14030431");

  const [showPopup, setShowPopup] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    setLoading(true);
    const formattedDate = requestedDate.replace(
      /(\d{2})(\d{2})(\d{2})(\d{2})/,
      "$2-$3-$4"
    );
    axios
      .get(`${BASE_URL}/api/predict/oscPredictObserve?date=${formattedDate}`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setThisTable(response.data);
        setThisTableInitial(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast("مشکلی پیش آمد");
        setLoading(false);
      });
    // axios
    //   .get(`${BASE_URL}/api/predict/oscPredictObserve`, {
    //     headers: {
    //       authorization: token,
    //     },
    //   })
    //   .then((response) => {
    //     setThisTableTest(response.data);
    //   })
    //   .catch((error) => {
    //     toast("مشکلی پیش آمد");
    //   });
  }, [requestedDate]);

  return (
    <div>
      {showPopup && (
        <div
          className="overlay"
          style={{ top: "auto", bottom: "0", position: "absolute" }}
        >
          <button className="closeButton" onClick={() => setShowPopup(false)}>
            ×
          </button>
          <p
            style={{
              borderRadius: "7px",
              backgroundColor: "white",
              width: "fit-content",
              marginRight: "auto",
              marginLeft: "auto",
              padding: "10px",
            }}
          >
            صحت‌سنجی پیش‌بینی نوسان طی هفته گذشته (5 روز کاری قبل)
          </p>
          <div className="tablesContainer" style={{ marginTop: "50px" }}>
            <table className="commonTable" id="predictTable">
              <thead>
                <tr>
                  <th>رتبه</th>
                  <th>سهم</th>
                  <th>تاریخ</th>
                  <th>نوسان هفتگی پیش‌بینی‌شده</th>
                  <th>احتمال</th>
                  <th>صحت</th>
                </tr>
              </thead>
              <tbody>
                {thisTableTest &&
                  thisTableTest
                    .filter((item) => item.confidence > 40)
                    .map((item, index) => (
                      <tr key={index}>
                        <td style={{ fontWeight: "bold" }}>{index + 1}</td>
                        <td style={{ fontWeight: "bold", cursor: "pointer" }}>
                          {item.name}
                        </td>
                        <td>{item.date}</td>
                        <td>
                          {item.label == 2
                            ? "مثبت"
                            : item.label == 1
                            ? "خنثی"
                            : "منفی"}
                        </td>
                        <td>{item.confidence.toFixed(1)}</td>
                        <td
                          style={{
                            color: `${item.success ? "green" : "red"}`,
                          }}
                        >
                          {item.success ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : (
                            <FontAwesomeIcon icon={faXmark} />
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <PredictSearch
        type={2}
        resetData={thisTableInitial}
        setter={setThisTable}
      />
      <div id="fundamentalHelp" style={{ textAlign: "right", padding: "5px" }}>
        <p>
          سهام با نوسان مثبت در طول هفتۀ آتی سقف 5 درصد نسبت به قیمت امروز را
          می‌شکنند (ممکن است دوباره به زیر این سقف برگردند). سهام رنج بین بازه
          -5 تا +5 نوسان خواهند داشت و سهام با نوسان منفی در طول هفتۀ آتی کف -5
          درصد نسبت به قیمت امروز را می‌شکنند (و البته ممکن است دوباره به بالای
          کف برگردند).
        </p>
        {/* <button
          style={{
            background:
              "linear-gradient(to right, #e31228, #00755E, #334456, white)",
            borderRadius: "7px",
            color: "white",
          }}
          onClick={() => setShowPopup(true)}
        >
          صحت‌سنجی نوسانگیر هفتۀ گذشته
        </button> */}
      </div>
      <div style={{ marginBottom: "20px" }}>
        <PickerPredict currentDate={"14030431"} setter={setRequestedDate} />
      </div>
      <div className="tablesContainer">
        <table className="commonTable" id="predictTable">
          <thead>
            <tr>
              <th>رتبه</th>
              <th>سهم</th>
              <th>نوسان طی هفته آینده</th>
              <th>احتمال</th>
            </tr>
          </thead>
          <tbody>
            {thisTable && !loading ? (
              thisTable.map((item, index) => (
                <tr key={index}>
                  <td style={{ fontWeight: "bold" }}>{index + 1}</td>
                  <td
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => {
                      window.open(`/dashboard/didehban/${item.name}`, "_blank");
                    }}
                  >
                    {item.name}
                  </td>
                  <td>
                    {item.label == 2
                      ? "مثبت"
                      : item.label == 1
                      ? "خنثی"
                      : "منفی"}
                  </td>
                  <td>{item.confidence.toFixed(1)}</td>
                </tr>
              ))
            ) : (
              <tr style={{ border: "none" }}>
                <td colSpan={6}>
                  <img src={LoadingGif} className="loadingGif" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OscillationPredict;
